import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Display,
  Grid,
  Heading
} from '@walmart-web/livingdesign-components';

import styles from './styles.module.css';
import NewRegistrationLayout from '../Layout';
import { freeTexts } from '../constants';
import {
  AndroidAppLinkWithQrCode,
  IosAppLinkWithQrCode
} from '../../../components/MobileAppLinkWithQrCode/index.ts';
import apiCall from '../../../utils/api';
import api from '../../../constants/api';
import API, { API_STATUS_CODE } from '../../../constants/common';
import SnackBanner from '../../../components/SnackBanner';
import { PAGES } from '../../../constants/analytics';
import analytics from '../../../utils/analytics';
import { onAppDownloadNowClick } from '../../../utils/common';

type LocationState = {
  emailId: string;
  pDetail: string;
};

type Props = {
  location: { state?: LocationState };
};

const NewRegistrationMobilePromotionBanner: React.FC<Props> = ({
  location
}) => {
  const history = useHistory();
  const [snackBanner, setSnackBanner] = useState({
    displaySnackBanner: false,
    snackBannerMessage: ''
  });

  const emailId = location.state?.emailId;
  const pDetail = location.state?.pDetail;

  useEffect(() => {
    // if emailId, pDetail, otp is not present in location state, redirect to external landing page
    if (!emailId || !pDetail) {
      history.replace('/');
    }
  }, [history, emailId, pDetail]);

  useEffect(() => {
    analytics.trackPageView(PAGES.REGISTRATION_DOWNLOAD_APP);
  }, []);

  const openSnackBanner = (message: string) => {
    setSnackBanner({
      displaySnackBanner: true,
      snackBannerMessage: message
    });
  };

  const handleSnackBannerClose = () => {
    setSnackBanner({
      displaySnackBanner: false,
      snackBannerMessage: ''
    });
  };

  const handleContinueButtonClick = () => {
    if (!emailId || !pDetail) return;
    const signInPayload = { userId: emailId, password: pDetail };
    apiCall(api.signIn, API.METHOD.POST, signInPayload, true).then(
      (response) => {
        if (response?.error) {
          openSnackBanner(
            `${response.error.error} \n ${response.error.message}`
          );
        }
        if (response?.status === API_STATUS_CODE.SUCCESS) {
          localStorage.setItem('platform', 'web');
          localStorage.setItem('token', response.data?.authToken);
          localStorage.setItem('refreshToken', response.data?.refreshToken);
          localStorage.setItem('panelistId', response.data?.panelistId);

          history.push('/home', 'newRecruitment');
        }
      }
    );
  };

  return (
    <NewRegistrationLayout
      footerButtonLabel="Continue on web"
      footerProgressPercentage="92"
      footerBtnclickAction={handleContinueButtonClick}
      registrationCompleted
    >
      <SnackBanner
        isSnackbarOpen={snackBanner.displaySnackBanner}
        snackbarMessage={snackBanner.snackBannerMessage}
        onSnackBarClose={handleSnackBannerClose}
      />
      <Container UNSAFE_className={styles.root}>
        <Grid UNSAFE_className={styles.headingContainer}>
          <Display
            weight={700}
            size="large"
            UNSAFE_className={styles.headingTypography}
          >
            {freeTexts.welcomeToCommunity}
          </Display>
        </Grid>

        <Grid UNSAFE_className={styles.subheadingContainer}>
          <Heading
            size="large"
            weight={400}
            UNSAFE_className={styles.subheadingTypography}
          >
            {freeTexts.registeredCongratulations}
            <br className={styles.lineBreak} /> {freeTexts.activitiesWaiting}
          </Heading>
        </Grid>

        <Grid UNSAFE_className={styles.appLinksContainer}>
          <AndroidAppLinkWithQrCode noMinimize />
          <IosAppLinkWithQrCode noMinimize />
        </Grid>

        <Grid UNSAFE_className={styles.downloadBtnContainer}>
          <Heading size="medium" weight={400}>
            <button
              className={styles.downloadButton}
              onClick={onAppDownloadNowClick}
            >
              Download
            </button>{' '}
            our app
          </Heading>
        </Grid>

        <Grid UNSAFE_className={styles.textContainer}>
          <Heading size="medium" weight={400}>
            {freeTexts.scanQrCode}
          </Heading>
        </Grid>
      </Container>
    </NewRegistrationLayout>
  );
};

export default NewRegistrationMobilePromotionBanner;
