import React from 'react';

import styles from '../styles.module.css';
import { APP_STORE_LINK } from '../../../constants/common';

const { ios: appStoreLink } = APP_STORE_LINK;

type Props = {
  noMinimize?: boolean;
};

const IosAppLinkWithQrCode: React.FC<Props> = ({ noMinimize = false }) => {
  return (
    <div className={styles.storeButtonWrapper}>
      <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
        <img
          src="/assets/images/mobile/appstore-white.png"
          alt="Download on the App Store"
          className={
            noMinimize ? styles.noMinimizeStoreButton : styles.storeButton
          }
        />
      </a>
      <img
        src="/assets/images/mobile/appstore-qr.png"
        alt="appstore qr code"
        className={noMinimize ? styles.noMinimizeQrCode : styles.qrCode}
      />
    </div>
  );
};

export default IosAppLinkWithQrCode;
