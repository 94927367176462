export const THANK_YOU = {
  title: 'Thank you for your feedback!',
  description:
    'Your voice makes a difference! Thank you for taking the time to respond today.'
};

export const SAVE_ERROR = {
  title: 'Sorry, something went wrong!',
  description:
    'Please try again. If the issue persists, contact us for support.'
};

export const ALREADY_TAKEN = {
  title: 'Thank you for your feedback!',
  description: 'It looks like you’ve already taken this survey.'
};

export const SURVEY_NOT_EXIST = {
  title: 'This survey does not exist.',
  description: ''
};

export const SURVEY_NOT_AUTHORIZED = {
  title: 'You are not authorized to see the survey.'
};

export const SURVEY_CLOSED = {
  title: 'Thanks for your interest!',
  description: 'This survey has closed.'
};

export const HEADER = {
  USER_ID: 'CS_CONSUMER.PANELIST_ID',
  AUTH_TOKEN: 'WM_SEC.AUTH_TOKEN',
  SVC_NAME: 'WM_SVC.NAME',
  SVC_ENV: 'WM_SVC.ENV'
};

export const FOOTER_HEADER = {
  CUSTOMER_SPARK: 'Customer Spark',
  WALMART: 'Walmart'
};

export const NA = 'N.A.';
export const IMAGE_FILE_TYPE = 'image';
export const VIDEO_FILE_TYPE = 'video';
export const PDF_FILE_TYPE = 'application/pdf';
export const MULTI_FILE_FORMAT = '.png,.jpg,.jpeg,.bmp,.mp4,.webm';

export const ALL_FORMAT_SUPPORT = '.png,.jpg,.jpeg,.bmp,.pdf';

export const ERROR_PULSE_SETUP_MSG = 'Error setting up pulse';

export const ERROR_SEND_PULSE_MSG = 'Error sending data to pulse';

export const PLEASE_REVIEW_YOUR_PROFILE = [
  `Please review your “Profile” information, and update any information that may have changed in the past 12 months. `,
  `If nothing has changed please continue.`
];

export const PROFILE_REVIEW_INFO = 'Review Info';

export const MAYBE_LATER = 'Maybe Later';

export const ANALYTICS = 'analytics';

export const MICRO_SURVEYS = {
  HEADER_TEXT: 'Quest',
  FEEDBACK_HEADER_TEXT: 'Feedback'
};
